// This file is automatically compiled by Vite, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import React from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import axios from 'axios';
import { BrowserRouter } from 'react-router-dom';
import { SessionContextProvider } from 'contexts/SessionContext';

import * as Sentry from '@sentry/react';
Sentry.init({
  dsn: import.meta.env.SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

document.addEventListener('DOMContentLoaded', () => {
  const csrfToken = document.querySelector('meta[name=csrf-token]')?.content;
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;

  const pages = import.meta.glob('../*.jsx');

  createInertiaApp({
    resolve: async (name) => {
      const page = pages[`../${name}.jsx`] || pages[`../App.jsx`];

      if (!page)
        throw new Error(
          `Unknown page ${name}. Is it located under pages with a .jsx extension?`,
        );

      const loaded = import.meta.env.SSR ? page : (await page()).default;
      return loaded;
    },
    setup: ({ el, App, props }) => {
      const SSR = el.hasChildNodes(); // Server-side rendering has added nodes
      if (SSR) {
        console.log('InertiaJS ⚡️ SSR');
        hydrateRoot(
          el,
          <BrowserRouter>
            <SessionContextProvider value={props.initialPage.props}>
              <App {...props} />
            </SessionContextProvider>
          </BrowserRouter>,
        );
      } else {
        // This should run only in dev, so we enable Strict Mode
        console.log('InertiaJS client-side (dev mode)');
        const root = createRoot(el);
        root.render(
          <React.StrictMode>
            <BrowserRouter>
              <SessionContextProvider value={props.initialPage.props}>
                <App {...props} />
              </SessionContextProvider>
            </BrowserRouter>
          </React.StrictMode>,
        );
      }
    },
  });
});
